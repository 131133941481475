import React from 'react';
import './Styles/TextPage.css';
import Loading from '../../Shared/Common/Loading';
import DynamicContent from '../Home/DynamicContent';
const TermsAndConditionsKeyword = 'terms-and-conditions'
const CookieKeyword = 'cookie-policy'
const TermsOfUseKeyword = 'terms-of-use'

const TermsOfUse = () => {
    return (
        <>
            <div className="col-12">
                <div className="row register form-container mt-3">
                    <div className="row px-3 pb-3">
                        <div className="col-12 text-page">
                            <DynamicContent keyword={TermsOfUseKeyword} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const CookiePolicy = (props) => {
    let acceptLabel = props.hasConsentedToCookies === "true" ? "Allowed" : "Allow";
    let acceptButtonDisabled = props.hasConsentedToCookies === "true";
    let denyButtonDisabled = props.hasConsentedToCookies === "false";
    let denyLabel = props.hasConsentedToCookies === "false" ? "Denied" : "Deny";
    return (
        <>
            <div className="col-12 text-page">
                <div className="row register form-container mt-3">
                    <div className="row px-3 pb-3">
                        <div className="col-12">
                            <h4>Manage Cookies</h4>
                            <table className="table">
                                <tbody><tr>
                                    <th className="text-nowrap">Category</th>
                                    <th>Description</th>
                                    <th colSpan="2">Status</th>
                                </tr>
                                    <tr>
                                        <td><strong>Strictly Necessary</strong></td>
                                        <td>These Cookies are needed to run our website, to keep it secure, and to obey regulations that apply to us.</td>
                                        <td colSpan="2"><strong>Always On</strong></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Functional</strong></td>
                                        <td>These Cookies remember your region or country, language, accessibility options and your settings. This also includes things like your User ID on the signing in page.</td>
                                        <td colSpan="2"><strong>Always On</strong></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Performance &amp; Tracking</strong></td>
                                        <td>These allow us to recognise and count the number of visitors and see how visitors move around our website when they are using it. This helps us to improve the way our website works. Targeting cooked record your visit to the website and pages you have visited. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</td>
                                        <td><button onClick={() => { props.setHasConsentedToCookies("true"); }} className="btn btn-success" disabled={acceptButtonDisabled}>{acceptLabel}</button></td>
                                        <td><button onClick={() => { props.setHasConsentedToCookies("false"); }} className="btn btn-success" disabled={denyButtonDisabled}>{denyLabel}</button></td>
                                    </tr>
                                    <tr className="hidden">
                                        <td><strong>Marketing</strong></td>
                                        <td>Our sites do not use any marketing Cookies.</td>
                                        <td colSpan="2">None</td>
                                    </tr>
                                </tbody></table>

                        </div>
                    </div>
                </div>
                <div className="row register form-container mt-3">
                    <div className="row px-3 pb-3">
                        <div className="col-12">
                            <DynamicContent keyword={CookieKeyword} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

const TAndC = () => {
    return (
        <>
            <div className="col-12 text-page">
                <div className="row register form-container mt-3">
                    <div className="row px-3 pb-3">
                        <div className="col-12">
                            <DynamicContent keyword={TermsAndConditionsKeyword} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

const PrivacyPolicy = (props) => {
    return (
        <>
            <div className="col-12 text-page">
                <div className="row register form-container mt-3">
                    <div className="row px-3 pb-3">
                        <div className='col-12'>
                            <div>
                                <p><strong>Privacy Notice</strong></p>
                                <p>The privacy and security of your personal information is important to us and we are committed to protecting it. It is important you read this privacy notice carefully because it explains how we collect and process personal data about you and why we are using your data. This privacy notice also sets out your privacy rights and how the data protection law protects you.</p>
                            </div>

                            <div>
                                <p><strong>Who we are and contact details</strong></p>
                                <p>Northgate Vehicle Sales Limited, trading as Van Monster (“we”, “us” or “our” in this privacy notice) is the “controller” of your personal data which means that we are responsible for deciding how we hold and use personal information about you.</p>
                                <p>If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact us at Data Protection, Northgate Vehicle Sales Limited, Northgate Centre, Lingfield Way, Darlington, DL1 4PZ or by email to <a href="mailto:dpo@reddenorthgate.com">dpo@reddenorthgate.com</a></p>
                            </div>

                            <div>
                                <p><strong>Changes to the privacy policy and your duty to inform us of changes</strong></p>
                                <p>We keep our privacy policy under regular review and we may need to make changes to it from time to time to take account of changes in law or the needs of our business. Please refer back to this page regularly to see any changes or updates to this privacy notice. This privacy notice was last updated in March 2024.</p>
                                <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us, this includes, but is not limited to any change of name, address or contact details.</p>
                            </div>

                            <div>
                                <p><strong>The data we collect about you</strong></p>
                                <p>Personal data, or personal information, means any information about a living individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</p>
                                <p>We will collect certain personal data from you when you use our website (vanmonster.com or vanmonsterremarketing.com), when you make contact with us about any of our vehicles and services, when signing up as a customer, in order to change or cancel your products and services and to provide customer support to you.</p>
                                <p>We may collect, use, store and transfer different kinds of personal data about you in connection with the relevant vehicle sale agreement made via the website or otherwise as a result of our dealings with you, which we have grouped together, including as follows:</p>
                                <ol>
                                    <li>Contact Data includes your name, email and postal address, telephone number and any other relevant information we need in order to contact or identify you.</li>
                                    <li>Financial Data includes bank account and payment details relating to products and services you receive from us and account number and sort code if you sell a vehicle via the Van Monster remarketing online auction.</li>
                                    <li>Transaction Data includes details of vehicles, products and services you have purchased from us.</li>
                                    <li>Technical Data includes internet protocol (IP) address which is a unique number identifying your computer, including information to identify your browser, device and network you are using.</li>
                                    <li>Usage Data includes information about how you use our website, products and services including the full uniform resource locators (URLs), clickstream to, through and from our website, products you viewed or searched for, page response times, length of visit, page interaction information.</li>
                                    <li>Marketing and Communications Data includes your preferences in receiving marketing and your communication preferences.</li>
                                </ol>
                            </div>

                            <div>
                                <p>We do not request any special categories of personal data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data) , however there may be occasions where we need to collect and process certain types of data, including special category data such as health, for the purpose of offering an enhanced level of support to customers who need it most. We do not collect any information about criminal convictions and offences from you.</p>
                            </div>

                            <div>
                                <p><strong>If you fail to provide personal data</strong></p>
                                <p>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you but we will notify you if this is the case at the time.</p>
                            </div>

                            <div>
                                <p><strong>How is your personal data collected?</strong></p>
                                <p>We use different methods to collect data from and about you including through:</p>
                                <ul>
                                    <li>Direct interactions. You may give us your personal data by filling in forms or by corresponding with us by post, phone, email, social media, via our website, customer portal or otherwise. This includes personal data you provide when you:</li>
                                    <ul>
                                        <li>apply for our products or services;</li>
                                        <li>make an enquiry via our website;</li>
                                        <li>request marketing to be sent to you;</li>
                                        <li>give us feedback or contact us. </li>
                                    </ul>
                                    <li>Use of our website, including live webchat services.</li>
                                    <li>Automated technologies or interactions. As you interact with our website, we will automatically collect technical data about your equipment, browsing actions and patterns. We collect this personal data by using cookies and other similar technologies. Please see the cookies and similar technologies section below and our cookie policy for further details.</li>
                                    <li>Telephone calls. We record telephone calls for training and monitoring purposes.</li>
                                </ul>
                            </div>

                            <div>
                                <p><strong>Information we receive about you may come from other organisations such as:</strong></p>
                                <ul>
                                    <li>credit reference agencies to confirm your identity and to prevent fraud and criminal activity, assess creditworthiness and product suitability, manage your account, trace and recover debts. You can find more information in the Credit Reference Agency Information Notice via <a href="https://www.experian.co/crain">www.experian.co/crain</a></li>
                                    <li>third parties with whom we are working (such as business partners and brokers, sub-contractors in technical, payment and delivery services, software and technology providers, advertising networks, analytics providers and search information providers), or someone else who provides you with goods and services and who thinks we have services or products that might be of interest to you.</li>
                                </ul>
                            </div>
                            <div>
                                <p><strong>The legal basis for processing your personal data</strong></p>
                                <p>We will only use your personal data when the law allows us to or where you have provided your consent. Most commonly, we will use your personal data in the following circumstances:</p>
                                <ul>
                                    <li>Where we need to perform the vehicle sale agreement/contract we are about to enter into or have entered into with you.</li>
                                    <li>Where it is necessary for our legitimate interests and your interests and fundamental rights do not override those interests.</li>
                                    <li>Where we need to comply with a legal obligation including sharing information with government or regulatory authorities, when required by law or in response to legal process, obligation or request including maintaining systems and controls to manage risk of financial crime including but not limited to compliance with the UK financial sanctions regime by screening details against and checking appropriate sanctions lists.</li>
                                </ul>
                                <p>We process your special category data (which is more sensitive, such as your health) in the following circumstances:</p>
                                <ul>
                                    <li>Where you have given us your specific consent.</li>
                                    <li>The processing is necessary for reasons of substantial public interest.</li>
                                </ul>
                                <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose.  Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.  If you give us false or inaccurate information and/or we suspect fraud, we will pass details to fraud prevention agencies.  Law enforcement agencies may access and use this information to detect and prevent fraud, for example when checking details on application for credit, managing credit accounts and completing credit searches.</p>
                            </div>
                            <div>
                                <p><strong>Marketing</strong></p>
                                <p>We will provide you with choices regarding certain personal data uses, particularly around marketing material. You will receive marketing communications from us if you have requested information from us or purchased goods or services from us and you have not opted out of receiving that marketing.</p>
                                <p>We may gather statistics around email opening and clicks using industry standard technologies including pixel trackers to help us monitor and improve email communications.</p>
                                <p><strong>Opting out of marketing messages</strong></p>
                                <p>You can ask us to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you by contacting us at any time by post, telephone, or email to dpo@reddenorthgate.com</p>
                                <p><strong>Third party websites and hyperlinks</strong></p>
                                <p>Our website may contain links to websites operated by third parties. The responsibility for the operation and content of those websites rests solely with the company controlling the third party website and will be governed by separate terms and conditions. We do not accept any responsibility or liability for the contents of any linked websites.</p>
                                <p>We encourage you to contact us through primary communication channels such as by telephone or email, as opposed to contacting us through social media channels. Our website may use social sharing buttons which help to share web content directly from web pages to the social media platform. Any such activities will be at your own discretion and risk and subject to the terms, conditions and privacy notices of the relevant social media platform.</p>
                                <p><strong>Cookies and similar technologies</strong></p>
                                <p>You can set your browser to refuse browser cookies (other than those which are strictly necessary), or to alert you when websites set or access cookies. Our website provides the ability for you to confirm or change your performance and advertising/tracking cookie preferences by clicking on the “manage cookies” option displayed on the cookie banner on the landing page of our website or by returning to the cookie policy page on our website. If you disable or refuse cookies, please note that some parts of our website may become inaccessible or not function properly. Further information identifying the name of, uses of information collected by, and expiry dates of the cookies used by our website, together with further information to explain how to change your cookie settings can be found on our cookie policy page.</p>
                                <p><strong>Gifs, pixel tags and other similar technologies</strong></p>
                                <p>GIFs (also known as pixels or web beacons) are small graphics with a unique identifier, similar in function to cookies, but whereas cookies are stored on the hard drive of your device, GIFs are invisibly embedded on web pages. We may use GIFs to track the activities of users of our services, gather statistics about the use of our services and to help us to manage content. We may also use GIFs in HTML emails to our customers to help us to track email response rates, to identify when our emails are read and to track if our emails are forwarded.</p>
                                <p><strong>Analytics</strong></p>
                                <p>We work with third party advertising partners, including Google, to reach individuals with more relevant content on and off our website and usage information related to our website and our ads and content. We use Google Analytics to evaluate the use of our website and services. You can find more information on Google Analytics <a href="#">here</a>. This information is only processed in a way that does not directly identify anyone. Google has developed a Google Analytics opt out browser add on for websites and you can find more information <a href="#">here</a>.</p>
                                <p>Analytics providers may use cookies and other similar technologies to perform their services and may combine the information they collect about you on our Sites with other information they have collected for their own purposes, this policy does not cover the use of data by third parties.</p>
                            </div>
                            <div>
                                <p><strong>Disclosures of your personal data</strong></p>
                                <p>Where necessary, we will share your personal data with:</p>
                                <ul>
                                    <li>Other companies within our group of companies.</li>
                                    <li>Redde Northgate Plc, a group company which provides a range of group operational support services to us, including HR, IT, legal, finance and taxation, risk and compliance advice and assistance.</li>
                                    <li>We may need to disclose personal information to our Principal firm, Consumer Credit Compliance Limited and if requested by the Financial Conduct Authority in order to provide certain regulated products and services and to comply with regulatory obligations.</li>
                                    <li>If you make payments by instalments, this will be arranged by way of a finance agreement with a finance provider based in the UK and authorised and regulated by the Financial Conduct Authority. To enable the finance provider to enter into a credit agreement with you, we will share your personal and payment details.  The finance provider will also be a controller of your data.  We will confirm the details of the finance provider in our correspondence with you.</li>
                                    <li>In order to arrange the complementary five day vehicle insurance and one month guaranteed asset protection (GAP) policy, we will share your personal details with an insurance broker to arrange this cover on your behalf.  The broker will also be a controller of your data and we will confirm their details in our correspondence with you.</li>
                                    <li>In order to provide an extended warranty, we will share your personal details with the warranty provider.  The warranty provider will also be a controller of your data and we will confirm their details in our correspondence with you.</li>
                                    <li>If you are eligible for, and you choose to purchase, a fuel card, we will share your personal data with the fuel card provider and we will confirm their details in our correspondence with you.</li>
                                    <li>If you choose to purchase a vehicle tracking device, we will share your personal data with the telematics tracking company which will also be a controller of your data.  We will confirm their details in our correspondence with you.</li>
                                    <li>Business partners, agents and carefully appointed third parties providing a service to us on our behalf to help us run our business, including payment service providers and website host.</li>
                                    <li>Credit reference agencies.</li>
                                    <li>Other organisations where we have a duty or are permitted to disclose your personal information by law (for example if we receive a valid request from an official organisation, including but not limited to a law enforcement agency or police force, in the interests of preventing or detecting crime or in connection with a court order).</li>
                                    <li>We will update the DVLA following a sale of a vehicle.</li>
                                    <li>Third parties we use to recover monies you or the organisation you represent may owe us (debt collection).</li>
                                    <li>Our insurer or representatives of the insurer (for example claims handlers, loss adjusters, solicitors) to comply with our insurance obligations.</li>
                                    <li>Other third parties or individuals if you have given us permission to do so or they are acting on your behalf.</li>
                                    <li>Legal, financial and other professional advisors and other organisations that have a specific role in law such as statutory and regulatory bodies.</li>
                                    <li>Another company if our business or part of it is bought or taken over by that company or during confidential discussions about a sale or take over.  We will ensure the information is protected by confidentiality agreements.</li>
                                </ul>
                                <p>Unless we are required to disclose your information by law, we will not share your personal data without safeguards being in place.  We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We have contracts in place with our third party data processors which means that they are only permitted to process your personal data for specified purposes and in accordance with our instructions and applicable law.</p>
                            </div>
                            <div>
                                <p><strong>Disclosures of your personal data</strong></p>
                                <p>Where necessary, we will share your personal data with:</p>
                                <ul>
                                    <li>Other companies within our group of companies.</li>
                                    <li>Redde Northgate Plc, a group company which provides a range of group operational support services to us, including HR, IT, legal, finance and taxation, risk and compliance advice and assistance.</li>
                                    <li>We may need to disclose personal information to our Principal firm, Consumer Credit Compliance Limited and if requested by the Financial Conduct Authority in order to provide certain regulated products and services and to comply with regulatory obligations.</li>
                                    <li>If you make payments by instalments, this will be arranged by way of a finance agreement with a finance provider based in the UK and authorised and regulated by the Financial Conduct Authority. To enable the finance provider to enter into a credit agreement with you, we will share your personal and payment details. The finance provider will also be a controller of your data. We will confirm the details of the finance provider in our correspondence with you.</li>
                                    <li>In order to arrange the complementary five day vehicle insurance and one month guaranteed asset protection (GAP) policy, we will share your personal details with an insurance broker to arrange this cover on your behalf. The broker will also be a controller of your data and we will confirm their details in our correspondence with you.</li>
                                    <li>In order to provide an extended warranty, we will share your personal details with the warranty provider. The warranty provider will also be a controller of your data and we will confirm their details in our correspondence with you.</li>
                                    <li>If you are eligible for, and you choose to purchase, a fuel card, we will share your personal data with the fuel card provider and we will confirm their details in our correspondence with you.</li>
                                    <li>If you choose to purchase a vehicle tracking device, we will share your personal data with the telematics tracking company which will also be a controller of your data. We will confirm their details in our correspondence with you.</li>
                                    <li>Business partners, agents and carefully appointed third parties providing a service to us on our behalf to help us run our business, including payment service providers and website host.</li>
                                    <li>Credit reference agencies.</li>
                                    <li>Other organisations where we have a duty or are permitted to disclose your personal information by law (for example if we receive a valid request from an official organisation, including but not limited to a law enforcement agency or police force, in the interests of preventing or detecting crime or in connection with a court order).</li>
                                    <li>We will update the DVLA following a sale of a vehicle.</li>
                                    <li>Third parties we use to recover monies you or the organisation you represent may owe us (debt collection).</li>
                                    <li>Our insurer or representatives of the insurer (for example claims handlers, loss adjusters, solicitors) to comply with our insurance obligations.</li>
                                    <li>Other third parties or individuals if you have given us permission to do so or they are acting on your behalf.</li>
                                    <li>Legal, financial and other professional advisors and other organisations that have a specific role in law such as statutory and regulatory bodies.</li>
                                    <li>Another company if our business or part of it is bought or taken over by that company or during confidential discussions about a sale or take over. We will ensure the information is protected by confidentiality agreements.</li>
                                </ul>
                                <p>Unless we are required to disclose your information by law, we will not share your personal data without safeguards being in place. We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We have contracts in place with our third party data processors which means that they are only permitted to process your personal data for specified purposes and in accordance with our instructions and applicable law.</p>
                            </div>

                            <div>
                                <p><strong>International transfers</strong></p>
                                <p>We do not generally process personal data outside the United Kingdom or European Economic Area, but we may do so where necessary for the purposes set out in this privacy notice and certain third-party service providers may transfer and process personal data outside the UK or EEA. We will only do this, or allow third parties to do so, where we are satisfied that your data is protected by equivalent laws and/or appropriate safeguards.</p>
                            </div>

                            <div>
                                <p><strong>Data security</strong></p>
                                <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
                                <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
                                <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to attempt to protect unauthorised access.</p>
                            </div>

                            <div>
                                <p><strong>Data retention</strong></p>
                                <p><strong>How long will you use my personal data for?</strong></p>
                                <p>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>
                                <p>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</p>
                                <p>If you sign up to our mailing list, we will keep your personal data for that purpose unless and until you tell us you want to unsubscribe or be removed from the list. If you opt out of any marketing material, we will delete your personal data (aside from keeping a record that you have asked us not to sent you marketing information).</p>
                                <p>In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</p>
                            </div>
                            <div>
                                <p><strong>Your legal rights</strong></p>
                                <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data. The rights available to you depend on our reason for processing your personal data.</p>
                                <p>You have the right to:</p>
                                <ul>
                                    <li>Request access to your personal data (commonly known as a "data subject access request" or “subject access request”). This enables you to receive a copy of the personal data we hold about you and supplementary information. You can always rely on this right, but there are some exemptions, which means you might not always receive all the information we process.</li>
                                    <li>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
                                    <li>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
                                    <li>Object to processing of your personal data where we are relying on a legitimate interest and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</li>
                                    <li>Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in certain circumstances.</li>
                                    <li>Request the transfer of your personal data to you or to a third party. This only applies to information you have given us.</li>
                                </ul>
                                <p>If you wish to exercise any of the rights set out above, please contact us at dpo@reddenorthgate.com or via the postal address above.</p>
                            </div>
                            <div>
                                <p><strong>No fee usually required</strong></p>
                                <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>
                                <p><strong>What we may need from you</strong></p>
                                <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
                                <p><strong>Time limit to respond</strong></p>
                                <p>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
                                <p><strong>Complaints</strong></p>
                                <p>You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK regulator for data protection issues (<a href="https://www.ico.org.uk">www.ico.org.uk</a>). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance at dpo@reddenorthgate.com or via the postal address above. If you remain dissatisfied, or you would prefer not to discuss the issue with us, you can also make a complaint to the ICO using the following contact details: <a href="https://ico.org.uk/make-a-complaint/">https://ico.org.uk/make-a-complaint/</a> or Information Commissioner’s Office, Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF or telephone: 0303 123 1113.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const Assist40 = (props) => {
    return (
        <>
            <div className="col-12 text-page">
                <div className="row register form-container mt-3">
                    <div className="row px-3 pb-3">
                        <div className="col-12">
                            <h4>What Checks are Included in the Assist40 Vehicle Check</h4>
                            <h2>Engine Bay</h2>
                            <ol><li>All level checks are carried out with engine off and cold an OK is when the level falls between the maximum and minimum visual indicators a Requires attention is when the level falls below the minimum visual indicator.</li>
                                <li>Oil contamination will be deemed as OK if the oil condition as viewed on the manufacturers level instrument is considered to be commensurate with the age and mileage of the vehicle a requires attention will indicate signs of water ingress.</li>
                                <li>The battery will be considered charged if it turns the engine over unaided by external power sources a requires attention will mean external power sources were required to turn the engine over.</li></ol>
                            <h2>Engine Operations</h2>
                            <ol><li>At the time of checking a YES will be issued if the vehicle starts unaided by any external power sources a NO will be issued if external power sources or any other means were used to start the engine.</li>
                                <li>Checks for noise, knocking and excessive engine smoke will be a NO if at the time of inspection if no audible or visual signs were present a YES will indicate that a noise, knocking and excessive engine smoke were audible and visual at the time of inspection and duly noted.</li>
                                <li>Secure fitting of the exhaust system will be deemed a YES if at the time of the inspection the system appears from a visual aspect secure and fitting correctly when stationary and not on an inspection ramp. A YES will be issued if the exhaust system has no audible blowing. A NO will be issued if the previous criteria were not met.</li>
                                <li>The engine running condition will be deemed a YES if at the time of inspection and with the vehicle stationary the engine is running with no hesitation or misfires a NO will be issued if the criteria is not met.</li></ol>
                            <h2>Dynamic Operations</h2>
                            <ol><li>The operation of the Central locking, Electric windows and the Power steering will be issued a YES if at the time of the inspection the operation of these are working correctly a NO will issued and noted against any that are not.</li>
                                <li>Handbrake operation will be carried out with the vehicle level and stationary. A YES will be issued if in the inspector’s opinion the operation is satisfactory. A NO will be issued if in the inspector’s opinion it is not satisfactory.</li>
                                <li>The operation of gear selection will be carried out with the engine running and the vehicle stationary. A YES will be issued if all gears can be selected including reverse a NO will be issued should any gear selection in the opinion of the inspector is not performing correctly.</li>
                                <li>The inspector will carry out a concise FIRST and REVERSE test drive of the vehicle and a YES will be issued if at the time of the inspection the operation of all associated components are performing as expected and taking into consideration the age and mileage of the vehicle. A NO will be issued should any of the YES criteria not be met.</li></ol>
                            <h2>Warning Light Checks</h2>
                            <ol><li>The warning light check will be performed with the engine running and the illuminated status will be recorded as at the time of inspection.</li></ol>
                            <h2>Windscreen Condition</h2>
                            <ol><li>The inspector will issue a YES if any chips to the windscreen are in the current M.O.T failure zone. A YES will be issued if there are any cracks to the windscreen.</li></ol>
                            <h2>Tyre Condition</h2>
                            <ol><li>A YES will be issued if the respective tyre has the legal minimum tread depth of 1.6mm in a continuous band across the central three quarters of the tyre. Any tyre falling below this requirement will be deemed as illegal and noted. The prospective purchaser must at the time of collection carry out their own checks before driving the vehicle on a public highway.</li></ol>
                            <h2>Documentation Check</h2>
                            <ol><li>Documentation checks will be done by the account managers and the availability status recorded on the inspection sheet. Any missing items must be taken as missing and no assurances will made of future availability.</li></ol>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

class TextPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    renderComponentState() {
        switch (this.props.path) {
            case "/TermsOfUse":
                return (
                    <TermsOfUse />
                );
            case "/TAndC":
                return (
                    <TAndC />
                );
            case "/PrivacyPolicy":
                return (
                    <PrivacyPolicy />
                );
            case "/CookiePolicy":
                return (
                    <CookiePolicy setHasConsentedToCookies={this.props.setHasConsentedToCookies} hasConsentedToCookies={this.props.hasConsentedToCookies} />
                );
            case "/Assist40":
                return (
                    <Assist40 />
                );
            default:
                return (
                    <Loading />
                );
        }
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 my-4">
                        {this.renderComponentState()}
                    </div>
                </div>
            </div>
        );
    }
}

export default TextPage;
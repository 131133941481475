import React, { Component } from 'react';
import './App.scss';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Home from './Containers/Home/Home';
import Help from './Containers/Help/Help';
import Partners from './Containers/About/Partners';
import About from './Containers/About/About';
import Sell from './Containers/Sell/Sell';
import Services from './Containers/About/Services';
import BuyersFees from './Containers/About/BuyersFees';
import TextPage from './Containers/Home/TextPage';
import Navbar from './Components/Navigation/Navbar';
import Footer from './Components/Footer/Footer';
import Auctions from './Containers/Auction/Auctions';
import Auction from './Containers/Auction/Components/Auction';
import Catalogue from './Containers/Auction/Components/Catalogue';
import VehicleDetail from './Containers/Auction/Components/VehicleDetail';
import * as signalR from '@aspnet/signalr';
import { authHeader } from './Shared/Utilities/AuthUtilities';
import { baseUrl } from './Shared/Utilities/AppConfig';
import Account from './Containers/User/Account';
import Register from './Components/Authentication/RegisterV2';
import Dashboard from './Containers/Dashboard/Dashboard';
import EditAccount from './Containers/User/EditAccount';
import { PropsRoute } from './Shared/Authorisation/PropsRoute';
import { PrivateRoute } from './Shared/Authorisation/PrivateRoute';
import ConfirmAccount from './Components/Authentication/ConfirmAccount';
import Registered from './Components/Authentication/Registered';
import ThankYou from './Components/Authentication/ThankYou';
import 'toastr/build/toastr.css';
import ContactUs from './Containers/ContactUs/ContactUs';
import ThanksForEnquiring from './Containers/ContactUs/ThanksForEnquiring';
import ForgotPassword from './Containers/User/Components/ForgotPassword';
import ResetPassword from './Containers/User/Components/ResetPassword';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import LoginPage from './Components/Authentication/LoginPage';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMapMarkerAlt, faTachometerFast, faInfoCircle, faCheck, faTimes, faClock, faHourglass, faHashtag, faStar, faSlidersH, faTimesCircle, faBell, faBars, faChevronLeft, faChevronRight, faChevronDown, faChevronUp, faUserHeadset, faWarehouse, faPoundSign, faSignOutAlt, faSignInAlt, faThumbsUp, faCircle, faHandHoldingBox, faCloudUpload, faFileChartLine, faGavel, } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import ServerRedirect from './Shared/Utilities/ServerRedirect';
import ScrollTop from './Shared/Utilities/ScrollTop';
import { apiGet } from './Shared/Utilities/ApiFetch';
import Error from './Shared/Common/Error';
import ReactGA from 'react-ga';
import BuyNow from './Containers/Auction/Components/BuyNow';
import { autoLogout } from './Shared/Utilities/AutoLogout';
import Search from './Containers/Search/Search';
import CookieModal from './Components/Modal/CookieModal'
import TermsAndConditionsModal from './Components/Modal/TermsAndConditionsModal';
import AlertModal from './Components/Modal/AlertModal';
import { isLoggedIn } from './Shared/Utilities/AuthUtilities';

import ThemeContextProvider from './ThemeContext';

import { MercedesTheme } from './Shared/Theme/MercedesTheme';
import Pledge from './Containers/Pledge/Pledge';

library.add(fal, fas, far, faMapMarkerAlt, faTachometerFast, faInfoCircle, faCheck, faTimes, faClock, faHourglass, faHashtag, faStar, faSlidersH, faTimesCircle, faBell, faBars, faChevronLeft, faChevronRight, faChevronDown, faChevronUp, faUserHeadset, faWarehouse, faPoundSign, faSignOutAlt, faSignInAlt, faThumbsUp, faCircle, faHandHoldingBox, faCloudUpload, faFileChartLine, faGavel, faUserHeadset)

const History = createBrowserHistory();

function setAnalytics() {
    if (!window.location.hostname.includes("localhost")) {
        let consent = getCookie('cookieConsent');
        const trackingId = "UA-151340109-1";
        if (consent === 'true') {
            ReactGA.initialize(trackingId);
            History.listen(location => {
                ReactGA.set({ page: location.pathname });
                ReactGA.pageview(location.pathname);
            });
        } else {
            window['ga-disable-' + trackingId] = true;
        }
    }
}

function setTracking() {
    if (!window.location.hostname.includes("localhost")) {
        let consent = getCookie('cookieConsent');
        if (consent === 'true') {
            let head = document.getElementsByTagName('head')[0];
            const script = document.createElement('script');
            script.src = "/DotDigital.js";

            const script2 = document.createElement('script');
            script2.src = "https://r1-t.trackedlink.net/_dmpt.js";

            head.appendChild(script);
            head.appendChild(script2);
        }
    }
}

setAnalytics();
setTracking();

function removeAllCookies() {
    document.cookie.split(";").forEach((c) => {
        document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

if (!iOS) {
    Notification.requestPermission(function (status) {
    });
}

const connection = new signalR.HubConnectionBuilder()
    .withUrl(baseUrl() + '/bidhub')
    .build();

const broadcastConnection = new signalR.HubConnectionBuilder()
    .withUrl(baseUrl() + '/broadcasthub')
    .build();

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            notifications: [],
            lastNotification: {},
            username: null,
            password: null,
            loginError: false,
            showNotificationModal: false,
            haveCheckedTermsAndConditions: false,
            theme: {},
            hasConsentedToCookies: false,
            hasConsentedToTrackingCookies: false,
            buyNowId: null,
            isUserLockedOut: null
        };
    }

    componentDidMount() {
        let cookie = this.getCookie('cookieConsent');
        this.setState({ hasConsentedToCookies: cookie });
        this.versionCheck();
    };

    getTheme = (id) => {
        if (isLoggedIn()) {
            apiGet('/api/Organisation/GetOrganisationForTheme/' + id, true)
                .then(result => {
                    let org = result.body.organisation.name;
                    if (org === "Mercedes-Benz") {
                        this.setState({ theme: MercedesTheme });
                    }
                })
                .catch(error => console.log(error));
        }
    }

    clearTheme = () => {
        this.setState({ theme: null });
    }

    getCookie = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    versionCheck = () => {
        apiGet('/api/Admin/GetVersion', false)
            .then(result => {
                var oldVersion = JSON.parse(localStorage.getItem('version'));
                localStorage.setItem('version', JSON.stringify(result.body));

                if (oldVersion && oldVersion !== result.body) {
                    window.location.reload(true);
                } else {
                    this.versionCheckComplete();
                }
            })
            .catch(error => this.versionCheckComplete());
    }

    versionCheckComplete = () => {
        this.getBuyNowId();
        this.startConnection(connection);
        this.startBroadcastConnection(broadcastConnection);

        let usr = document.getElementById('username');
        let pwd = document.getElementById('password');
        this.setState({ username: usr, password: pwd });

        let email = null;
        if (this.state.user != null) {
            email = this.state.user.email;
            this.messageHandler(connection, null, email);
            this.broadcastMessageHandler(broadcastConnection);
        }

        this.updateUser();
    }

    startConnection = (connection) => {
        connection.start();
        this.setState({ connected: true });
    }

    startBroadcastConnection = (broadcastConnection) => {
        broadcastConnection.start();
    }

    messageHandler = async (connection, oldEmail, newEmail) => {
        if (oldEmail) {
            connection.off(oldEmail);
        }

        if (newEmail) {
            connection.on(newEmail, (message) => {
                this.setState({ notifications: [...this.state.notifications, message] }, this.displayNotification(message));
            });
        }
    }

    broadcastMessageHandler = async (broadcastConnection) => {
        broadcastConnection.on("BroadcastEvent", (message) => {
            this.setState({ notifications: [...this.state.notifications, message], lastNotification: message },
                this.displayNotification(message));
        });
    }

    displayNotification = (message) => {
        this.updateUser();
        const messageText = message.message;
        if (Notification.permission === 'granted' && navigator.serviceWorker) {
            navigator.serviceWorker.getRegistration().then(function (reg) {
                if (reg === undefined) {
                    this.displayAlertModal(messageText);
                }
                else {
                    reg.showNotification(messageText);
                }
            }.bind(this))
        }
        else {
            this.displayAlertModal(messageText);
        };
    }

    displayAlertModal = (message) => {
        this.setState({ alertMessage: message });
    }

    hideAlertModal = () => {
        this.setState({ alertMessage: null });
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    markAsSeen = (notificationId) => {
        let dataToPost = {
            Id: notificationId
        };

        const notification = document.querySelectorAll('.notifications-container[value="' + notificationId + '"]')
        notification[0].style.display = "none";

        const localUser = localStorage.getItem('user');
        const user = JSON.parse(localUser);
        var token = 'bearer ' + user.token;

        fetch(baseUrl() + '/api/notification/seen', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'authorization': token
            },
            body: JSON.stringify(dataToPost)
        }).then(response => this.updateUser());
    }

    markAllAsSeen = () => {
        const localUser = localStorage.getItem('user');
        const user = JSON.parse(localUser);
        var token = 'bearer ' + user.token;

        fetch(baseUrl() + '/api/notification/MarkAllAsSeen', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'authorization': token
            }
        }).then(response => this.updateUser());
    }

    updateUser = () => {
        let oldEmail = null;

        if (this.state.user != null) {
            oldEmail = this.state.user.email;
            if (this.state.isUserLockedOut === null || this.state.isUserLockedOut === false) {
                this.isUserLockedOut();
            }
        }

        fetch(baseUrl() + '/api/account/user', {
            method: 'get',
            headers: authHeader()
        }).then(response => response.json())
            .then(data => this.setState({ user: data }, () => {
                let newEmail = null;
                if (this.state.user != null) {
                    newEmail = this.state.user.email;
                }

                if (oldEmail !== newEmail) {
                    this.messageHandler(connection, oldEmail, newEmail);
                }
                autoLogout.setup();
            })
            );
    }

    isUserLockedOut = () => {
        // if (this.state.isUserLockedOut === null || this.state.isUserLockedOut === false) {
        apiGet(`/api/Account/IsUserLockedOut/`, true)
            .then(result => {
                if (result.body.isLockedOut) {
                    this.setState({ isUserLockedOut: true });
                    this.handleLogout();
                } else {
                    this.setState({ isUserLockedOut: false });
                }
            });
        // }
    }

    handleLogout = () => {
        autoLogout.logout();
    }

    goBack = () => {
        History.goBack();
    }

    handleLogin = () => {
        if (this.state.username !== '' && this.state.password !== '') {
            this.setState({ loginError: false });

            let dataToPost = {
                deviceType: 0,
                deviceId: 'AADB606F-369B-44F6-8C8D-2689C6F12425',
                username: this.state.username,
                password: this.state.password
            };

            fetch(baseUrl() + '/api/account/login', {
                method: 'POST',
                credentials: "same-origin",
                body: JSON.stringify(dataToPost),
                headers: {
                    'content-type': 'application/json'
                }
            }).then(response => response.json())
                .then(user => {
                    if (user.suceeded !== false) {
                        localStorage.setItem('user', JSON.stringify(user));
                    }
                    else {
                        this.setState({ loginError: true, loginResponseMessage: user.message });
                    }
                }).then(() => {
                    autoLogout.triggerNewLogin();
                    this.getBuyNowId();
                    this.updateUser();
                });
        }
    }

    handleLoginPageLogin = () => {
        if (this.state.username !== '' && this.state.password !== '') {
            this.setState({ loginPageError: false });

            let dataToPost = {
                username: this.state.username,
                password: this.state.password
            };

            fetch(baseUrl() + '/api/account/login', {
                method: 'POST',
                credentials: "same-origin",
                body: JSON.stringify(dataToPost),
                headers: {
                    'content-type': 'application/json'
                }
            }).then(response => response.json())
                .then(user => {
                    if (user.suceeded !== false) {
                        localStorage.setItem('user', JSON.stringify(user));
                        History.push(`/`);
                    }
                    else {
                        this.setState({ loginPageError: true, loginResponseMessage: user.message });
                    }
                }).then(() => {
                    autoLogout.triggerNewLogin();
                    this.updateUser();
                    this.getBuyNowId();
                });
        }
    }

    clearErrors = (response) => {
        if (response.errors) {
            let summary = document.getElementById(`ValidationSummary`);
            if (summary !== undefined) summary.innerHTML = '';

            response.errors.forEach(
                (errorObject) => {
                    let el = document.getElementById(`${errorObject.key}Error`);
                    let input = document.getElementById(`${errorObject.key}`);

                    if (el !== null) {
                        el.innerHTML = ""
                    }

                    if (input !== null) {
                        input.classList.remove("errorBorder");
                    }
                }
            )
        }
    }

    toggleNotificationModal = () => {
        let show = this.state.showNotificationModal === true ? false : true;
        this.setState({ showNotificationModal: show })
    }

    handleErrors = (response) => {
        if (response.errors) {
            let summary = document.getElementById(`ValidationSummary`);
            if (summary !== undefined) summary.innerHTML = response.message;

            response.errors.forEach(
                (errorObject) => {
                    let el = document.getElementById(`${errorObject.key}Error`);
                    let input = document.getElementById(`${errorObject.key}`);

                    if (el !== null) {
                        el.innerHTML += errorObject.error
                    }

                    if (input !== null) {
                        input.classList.add("errorBorder");
                    }
                }
            )
        }
    }

    getBuyNowId = () => {
        apiGet('/api/auction/GetBuyNowId', true)
            .then(result => this.setState({ buyNowId: result.body }))
    }

    addToPageHistory = (url) => {
        History.push(url);
    }

    setHaveCheckedTermsAndConditions = (value) => {
        this.setState({ setHaveCheckedTermsAndConditions: value });
    }

    setHasConsentedToCookies = (value) => {
        this.setState({ hasConsentedToCookies: value },
            () => {
                removeAllCookies();
                this.createConsentCookie(value);
                setAnalytics();
                setTracking();
            });
    }

    createConsentCookie = (consent) => {
        var d = new Date();
        d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = "cookieConsent=" + consent + ";" + expires + ";path=/";
    }

    render() {
        return (
            <ThemeContextProvider theme={this.state.theme}>
                {
                    <Router history={History}>
                        <ServerRedirect>
                            <ScrollTop>
                                <Navbar
                                    user={this.state.user}
                                    updateUser={this.updateUser}
                                    handleLogin={this.handleLogin}
                                    messageHandler={this.messageHandler}
                                    handleLogout={this.handleLogout}
                                    markAsSeen={this.markAsSeen}
                                    markAllAsSeen={this.markAllAsSeen}
                                    handleChange={this.handleChange}
                                    loginError={this.state.loginError}
                                    loginResponseMessage={this.state.loginResponseMessage}
                                    buyNowId={this.state.buyNowId}
                                    notifications={this.state.user ? this.state.user.notifications : null}
                                />
                                <div className="flex-wrapper">
                                    <div className="fill-height">
                                        <Switch>
                                            <Route exact path="/" component={Home} />
                                            <Route exact path="/Partners" component={Partners} />
                                            <PropsRoute exact path="/Services" component={Services} buyNowId={this.state.buyNowId} />
                                            <Route exact path="/About" component={About} />
                                            <PropsRoute exact path="/Sell" component={() => <Sell user={this.state.user} />} />
                                            <PropsRoute exact path="/Pledge" component={Pledge} />

                                            <Route exact path="/BuyersFees" component={BuyersFees} />
                                            <PropsRoute exact path="/TermsOfUse" component={TextPage} />
                                            <PropsRoute exact path="/TAndC" component={TextPage} />
                                            <PropsRoute exact path="/PrivacyPolicy" component={TextPage} />
                                            <PropsRoute exact path="/CookiePolicy" component={TextPage} setHasConsentedToCookies={this.setHasConsentedToCookies} hasConsentedToCookies={this.state.hasConsentedToCookies} setHasConsentedToTrackingCookies={this.setHasConsentedToTrackingCookies} hasConsentedToTrackingCookies={this.state.hasConsentedToTrackingCookies} />
                                            <PropsRoute exact path="/Assist40" component={TextPage} />

                                            <PropsRoute exact path="/Search" component={Search} addToPageHistory={this.addToPageHistory} />

                                            <PropsRoute exact path="/Help" component={Help} />
                                            <PropsRoute exact path="/Help/:id" component={Help} />

                                            {this.state.buyNowId && <PropsRoute exact path="/BuyNow" clearTheme={this.clearTheme} getTheme={this.getTheme} buyNowId={this.state.buyNowId} component={BuyNow} lastNotification={this.state.lastNotification} crumbs={[["Buy Now", "/BuyNow"]]} addToPageHistory={this.addToPageHistory} />}
                                            {this.state.buyNowId && <PropsRoute path="/BuyNow/:id" clearTheme={this.clearTheme} getTheme={this.getTheme} component={VehicleDetail} goBack={this.goBack} crumbs={[["Auctions", "/Auctions"], ["Buy now", "/BuyNow"]]} />}

                                            <PropsRoute path="/Auctions" component={Auctions} lastNotification={this.state.lastNotification} />
                                            <PropsRoute path="/Auction/:id" clearTheme={this.clearTheme} getTheme={this.getTheme} buyNowId={null} component={Auction} lastNotification={this.state.lastNotification} crumbs={[["Auctions", "/Auctions"]]} addToPageHistory={this.addToPageHistory} isUserLockedOut={this.isUserLockedOut} />
                                            <PropsRoute path="/AuctionVehicle/:auctionId/:id" component={VehicleDetail} getTheme={this.getTheme} clearTheme={this.clearTheme} goBack={this.goBack} crumbs={[["Auctions", "/Auctions"], ["Auction", "/Auction/{auctionId}"]]} />

                                            <PropsRoute path="/Catalogue/:id" component={Catalogue} clearTheme={this.clearTheme} getTheme={this.getTheme} crumbs={[["Auctions", "/Auctions"]]} />
                                            <PropsRoute path="/Vehicle/:id" component={VehicleDetail} goBack={this.goBack} crumbs={[["Vehicles", "/Vehicles"]]} />

                                            <Route path="/Register" component={Register} />
                                            <PropsRoute path="/ThanksForRegistering" component={Registered} crumbs={[["Register", "/Register"]]} />
                                            <PropsRoute path="/ThankYou" component={ThankYou} />

                                            <PropsRoute exact path="/Login" component={LoginPage} user={this.state.user} loginError={this.state.loginPageError} loginResponseMessage={this.state.loginResponseMessage} handleChange={this.handleChange} handleLogin={this.handleLoginPageLogin} />
                                            <PrivateRoute exact path="/MyAccount" component={Account} />

                                            <PrivateRoute path="/MyAccount/Edit" component={EditAccount} crumbs={[["My Account", "/MyAccount"]]} />
                                            <PropsRoute exact path="/ForgotPassword" component={ForgotPassword} />
                                            <PropsRoute exact path="/ResetPassword/:token" component={ResetPassword} />

                                            <Route exact path="/ConfirmAccount/:token" component={ConfirmAccount} />

                                            <Route path="/Dashboard" component={Dashboard} />

                                            <Route path="/ContactUs" component={ContactUs} />
                                            <PropsRoute path="/ThanksForEnquiring" component={ThanksForEnquiring} crumbs={[["Contact Us", "/ContactUs"]]} />
                                            <PropsRoute component={Error} error={{ status: 404, statusText: "Not found" }} />

                                            <Redirect to="/" />
                                        </Switch>
                                        <CookieModal hasConsentedToCookies={this.state.hasConsentedToCookies} setHasConsentedToCookies={this.setHasConsentedToCookies} addToPageHistory={this.addToPageHistory} />
                                        <TermsAndConditionsModal haveCheckedTermsAndConditions={this.state.haveCheckedTermsAndConditions} user={this.state.user} setHaveCheckedTermsAndConditions={this.setHaveCheckedTermsAndConditions} />
                                        <AlertModal alertMessage={this.state.alertMessage} clearAlertMessage={this.hideAlertModal} />
                                    </div>
                                    <Footer />
                                </div>
                            </ScrollTop>
                        </ServerRedirect>
                    </Router>
                }
            </ThemeContextProvider>
        );
    }
}

export default App;
import decode from 'jwt-decode'

export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}
export function getUserId() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return user.id;
    } else {
        return {};
    }
}
export function isLoggedIn() {
    var response = false;
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        const decoded = decode(user.token);
        //Check expiry
        response = ((decoded.exp * 1000) > new Date().getTime());
        if (response === false) {
            //If they're not logged in remove token - so request to the api will fail
            localStorage.removeItem('user');
            window.location.reload(true);
        }
    }

    return response;
}

export function isAllowed(right) {
    // return authorization header with jwt token
    var response = false;
    if (isLoggedIn()) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            const decoded = decode(user.token);
            response = decoded[right] === 'true';
        }
    }
    return response;
}